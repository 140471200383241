import type { Props as HeadlineModuleProps } from '@hypercodestudio/basler-components/dist/components/modules/HeadlineModule.vue';
import type { HeadlineProps } from '@hypercodestudio/basler-components/dist/components/content-modules/sections/HeroProductSection.vue';
import {
  type IHeroArticleSection,
  type IHeroOverviewSection,
  type IHeroProductSection
} from '~/lib/ContentfulService';
import { mapHeadline } from '~/utils/mapHeadline';
import { isDefined } from '~/utils/guards/isDefined';
import { isEntryResolveError } from '~/utils/guards/isEntryResolveError';

export function mapHeroSubtitle(
  heroItem: IHeroArticleSection | IHeroOverviewSection | IHeroProductSection
): (HeadlineProps & HeadlineModuleProps) | undefined {
  if (
    isDefined(heroItem.fields.advancedSubtitle) &&
    !isEntryResolveError(heroItem.fields.advancedSubtitle)
  ) {
    return mapHeadline(heroItem.fields.advancedSubtitle);
  }
}
